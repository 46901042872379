<template>
    <div class="container-scroller">
    <loading :active="isLoading" :is-full-page="fullPage"/>
    <!-- partial:partials/_navbar.html -->
    <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <a class="navbar-brand brand-logo mr-5" href="/"><img src="images/logo.png" class="mr-2" alt="logo"/></a>
        <a class="navbar-brand brand-logo-mini" href="/"><img src="images/logo.png" alt="logo"/></a>
      </div>
      <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
        <ul class="navbar-nav mr-lg-2" style="width: 100%" v-if="data?.items">
          <li class="nav-item nav-search d-lg-block" style="width: 100%">
            <div class="input-group">
              <div class="input-group-prepend hover-cursor" id="navbar-search-icon">
                <span class="input-group-text" id="search">
                  <i class="icon-search"></i>
                </span>
              </div>
              <input type="text" class="form-control" id="navbar-search-input" placeholder="Pesquisa (ex: 2055516)" aria-label="search" aria-describedby="search" :value="search" @input="doSearch" @keyup.enter="loadData()">
            </div>
          </li>
        </ul>
        <ul class="navbar-nav navbar-nav-right">
          <li class="nav-item nav-profile dropdown">
            <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
              <img src="images/user.png" alt="profile"/> {{currentUser.email}}
            </a>
            <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
              <a class="dropdown-item">
                <i class="ti-user text-primary"></i>
                Profile
              </a>
              <a href="/logout" class="dropdown-item">
                <i class="ti-power-off text-primary"></i>
                Logout
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <!-- partial -->
    <div class="container-fluid page-body-wrapper">
      <!-- partial:partials/_sidebar.html -->
      <!-- partial -->
      <div class="main-panel">
        <div class="content-wrapper" v-if="data?.items?.length">
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h2>{{ data.search }}</h2>
                  <p>{{ data.total }} resultados</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card position-relative">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12 col-xl-3 d-flex flex-column justify-content-start">
                      <div class="ml-xl-4 mt-3">
                        <p class="card-title text-info text-uppercase">Preço Médio</p>
                        <h1 class="text-info">{{ ((parseFloat(data?.max?.value.toString().replace('€', '')) + parseFloat(data?.min?.value.toString().replace('€', '')))/2).toFixed(2) }}€</h1>
                      </div>
                      <div class="ml-xl-4 mt-3">
                        <p class="card-title">Preço Minimo</p>
                        <h3>{{ parseFloat(data?.min?.value.toString().replace('€', '')).toFixed(2) }}€</h3>
                        <h5 class="mb-xl-4">{{ data?.min?.source }}</h5>
                      </div>
                      <div class="ml-xl-4 mt-3">
                        <p class="card-title">Preço Máximo</p>
                        <h3>{{ parseFloat(data?.max?.value.toString().replace('€', '')).toFixed(2) }}€</h3>
                        <h5 class="mb-xl-4">{{ data?.max?.source }}</h5>
                      </div>  
                    </div>
                    <div class="col-md-12 col-xl-9">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="card">
                            <div class="card-body">
                              <CanvasJSChart :options="chartOpt.options" :styles="chartOpt.styleOptions" @chart-ref="chartInstance" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>
                            Fornecedor
                          </th>
                          <th>
                            Marca
                          </th>
                          <th>
                            Descrição
                          </th>
                          <th>
                            Stock
                          </th>
                          <th>
                            Preço
                          </th>
                          <th>Ver na loja</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(product, index) in data.items" :key="index">
                          <td class="py-1">
                            <img :src="`images/suppliers/${product.source}.png`" alt="image"/>
                          </td>
                          <td>
                            {{ product.brand }}
                          </td>
                          <td>
                            {{ product.description }}
                          </td>
                          <td>
                            {{ product.stock }}
                          </td>
                          <td>
                            {{ parseFloat(product.price.toString().replace('€', '')).toFixed(2) }} 
                          </td>
                          <td>
                            <a :href="product.source_url">Ver loja</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="content_detail__pagination cdp" :actpage="paginationPage">
                    <a class="cdp_i" @click="goToPage('-1')">anterior</a>
                    <a v-for="index in data.pages" :key="index" class="cdp_i" @click="goToPage(index)">{{ index }}</a>
                    <a class="cdp_i" @click="goToPage('+1')">próxima</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-wrapper" v-if="!data?.items">
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <ul class="navbar-nav mr-lg-2" style="width: 100%">
                    <li class="nav-item nav-search d-lg-block" style="width: 100%">
                      <div class="input-group">
                        <div class="input-group-prepend hover-cursor" id="navbar-search-icon">
                          <span class="input-group-text" id="search">
                            <i class="icon-search"></i>
                          </span>
                        </div>
                        <input type="text" class="form-control" id="navbar-search-input" placeholder="Pesquisa (ex: 2055516)" aria-label="search" aria-describedby="search" :value="search" @input="doSearch" @keyup.enter="loadData()">
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-wrapper" v-if="data?.items && data?.items?.length === 0">
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h2>{{ data.search }}</h2>
                  <p>{{ data.total }} resultados</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
        <footer class="footer">
          <div class="d-sm-flex justify-content-center justify-content-sm-between">
            <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2024. All rights reserved.</span>
          </div>
          <div class="d-sm-flex justify-content-center justify-content-sm-between">
            <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Distributed by <a href="https://www.walkindev.com/" target="_blank">Walk In DEV</a></span> 
          </div>
        </footer> 
        <!-- partial -->
      </div>
      <!-- main-panel ends -->
    </div>   
    <!-- page-body-wrapper ends -->
  </div>
  
      
  </template>
  
  <script>
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/css/index.css';
import userService from '../services/user.service';
  
  const generateRandomColors = (N) => {
    var colors = [];
    for (var i = 0; i < N; i++) {
        var color = '#' + ('00000'+(Math.random()*(1<<24)|0).toString(16)).slice(-6);
        colors.push(color);
    }
    return colors;
  }

  export default {
    name: "Home-Page",
    components: {
      Loading
    },
    data() {
      return {
        search: "",
        data: {},
        paginationPage: 1,
        isLoading: false,
        isError: false,
        chart: null,
        chartOpt: {
          options: {
            theme: "light2",
            animationEnabled: true,
            title: {
              text: "Resultados por fornecedor"
            },
            data: [{
              type: "doughnut",
              startAngle: 90,
              indexLabel: "{label} {y} (#percent%)",
              yValueFormatString: "#",
              toolTipContent: "<span style='\"'color: {color};'\"'>{label}</span>, {y}",
              dataPoints: []
            }]
          },
          styleOptions: {
            width: "100%",
            height: "360px"
          }
        }
      };
    },
    computed: {
      currentUser() {
        return this.$store.state.auth.user;
      }
    },
    mounted() {
      if (!this.currentUser) {
        this.$router.push('/login');
      }
    },
    methods: {
      goToPage(page) {
        if (page === '+1') {
          this.paginationPage += 1;
        } else if (page === '-1') {
          this.paginationPage -= 1;
        }else{
          this.paginationPage = parseInt(page, 10);
        }
        this.loadData(this.paginationPage, this.data.id);
      },
      doSearch(e) {
        console.log(e.target.value);
        this.search = e.target.value;
      },
      async loadData(page = 1, id) {
        try {
          this.isLoading = true;
          const { data } = await userService.getSearchResults({
            search: this.search,
            page,
            id
          });
          this.data = data;
          const differentColors = generateRandomColors(Object.keys(data.suppliers).length);
          this.chartOpt.options.data[0].dataPoints = [];
          Object.keys(data.suppliers).forEach((supplier, index) => {
            this.chartOpt.options.data[0].dataPoints.push({ label: supplier, y: data.suppliers[supplier], color: differentColors[index] });
          });
          this.chart.render();
        } catch (err) {
          this.isError = true;
        } finally {
          this.isLoading = false;
        }
      },
      chartInstance(chart) {
        this.chart = chart;
      }
    }
  };
  </script>

<style lang="scss">
@keyframes cdp-in {
  from {
    transform: scale(1.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.cdp {
  position: relative;
  text-align: center;
  padding: 20px 0;
  font-size: 0;
  z-index: 6;
  
  animation: cdp-in 500ms ease both;
  animation-timeout: 200ms;

  &_i {
    font-size: 14px;
    text-decoration: none;
    
    transition: background 250ms;
    
    display: inline-block;
    text-transform: uppercase;
    margin: 0 3px 6px;
    height: 38px;
    min-width: 38px;
    border-radius: 38px;
    border: 2px solid #000;
    line-height: 38px;
    padding: 0;
    color: #000;
    font-weight: 700;
    letter-spacing: .03em;
    display: none;

    &:first-child,
    &:last-child {
      padding: 0 16px;
      margin: 0 12px 6px;
    }

    &:last-child,
    &:nth-child(2),
    &:nth-last-child(2) {
      display: inline-block;
    }
  }

  &_i:hover {
    background-color: #fff;
    color: #000;
  }

  &:not([actpage="1"]) &_i:nth-child(1) {
    display: inline-block;
  }
}
@for $i from 1 through 80 {
  .cdp[actpage="#{$i}"] {
    // 3 before
    .cdp_i:nth-child(#{$i - 2}):not(:first-child):not(:nth-child(2)) {
      display: inline-block;
      pointer-events: none;
      color: transparent;
      border-color: transparent;
      width: 50px;
      &:after {
        content: '...';
        color: #000;
        font-size: 32px;
        margin-left: -6px;
      }
    }
    // 2 before
    .cdp_i:nth-child(#{$i - 1}):not(:first-child) {
      display: inline-block;
    }
    // before
    .cdp_i:nth-child(#{$i}):not(:first-child) {
      display: inline-block;
    }
    // active
    .cdp_i:nth-child(#{$i + 1}) {
      background-color: #000;
      color: #fff;
      display: inline-block;

      +.cdp_i:last-child {
        display: none !important;
      }
    }
    // next
    .cdp_i:nth-child(#{$i + 2}):not(:last-child) {
      display: inline-block;
    }
    // 2 next
    .cdp_i:nth-child(#{$i + 3}):not(:last-child) {
      display: inline-block;
    }
    // 3 next
    .cdp_i:nth-child(#{$i + 4}):not(:last-child):not(:nth-last-child(2)) {
      display: inline-block;
      pointer-events: none;
      color: transparent;
      border-color: transparent;
      width: 50px;
      &:after {
        content: '...';
        color: #000;
        font-size: 32px;
        margin-left: -6px;
      }
    }
  }
}
</style>

<template>
  <div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
      <div class="content-wrapper d-flex align-items-center auth px-0">
        <div class="row w-100 mx-0">
          <div class="col-lg-4 mx-auto">
            <div class="auth-form-light text-left py-5 px-4 px-sm-5">
              <div class="brand-logo">
                <img src="images/logo.png" alt="logo">
              </div>
              <h4>Nova conta?</h4>
              <h6 class="font-weight-light">Registe-se agora para ter acesso à plataforma.</h6>
              <Form @submit="handleRegister" :validation-schema="schema" class="pt-3">
                <div v-if="!successful">
                  <div class="form-group">
                    <label for="name">Nome</label>
                    <Field name="name" type="text" class="form-control form-control-lg" placeholder="Nome" />
                    <ErrorMessage name="name" class="error-feedback" />
                  </div>
                  <div class="form-group">
                    <label for="email">Email</label>
                    <Field name="email" type="email" class="form-control form-control-lg" placeholder="Email" />
                    <ErrorMessage name="email" class="error-feedback" />
                  </div>
                  <div class="form-group">
                    <label for="password">Password</label>
                    <Field name="password" type="password" class="form-control form-control-lg" placeholder="Password" />
                    <ErrorMessage name="password" class="error-feedback" />
                  </div>
                  <div class="form-group">
                    <Field name="terms" type="checkbox" value="true" /> Concordo com os termos e condições<br/>
                    <ErrorMessage name="terms" class="error-feedback" />
                  </div>
                  <div class="form-group">
                    <button class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" :disabled="loading">
                        <span
                        v-show="loading"
                        class="spinner-border spinner-border-sm"
                        ></span>
                        Registar
                    </button>
                  </div>
                </div>
              </Form>
              <div
                v-if="message"
                class="alert"
                :class="successful ? 'alert-success' : 'alert-danger'"
              >
                {{ message }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>    
</template>
  
  <script>
  import { Form, Field, ErrorMessage } from "vee-validate";
  import * as yup from "yup";
  
  export default {
    name: "Register-Page",
    components: {
      Form,
      Field,
      ErrorMessage,
    },
    data() {
      const schema = yup.object().shape({
        name: yup
          .string()
          .required("Nome é obrigatório!")
          .min(3, "Nome demasiado pequeno!")
          .max(20, "Nome demasiado grande! Máximo 20 caracteres!"),
        email: yup
          .string()
          .required("Email é obrigatório!")
          .email("Email é inválido!")
          .max(50, "Email demasiado grande! Máximo 50 caracteres!"),
        password: yup
          .string()
          .required("Password é obrigatória!")
          .min(6, "Password é demasiado pequena! Minimo 6 caracteres!")
          .max(40, "Password é demasiado grande! Máximo 40 caracteres!"),
        terms: yup
          .bool()
          .required("Tem de aceitar os Termos e Condições.")
          .oneOf([true], "Tem de aceitar os Termos e Condições."),
      });
  
      return {
        successful: false,
        loading: false,
        message: "",
        schema,
      };
    },
    computed: {
      loggedIn() {
        return this.$store.state.auth.status.loggedIn;
      },
    },
    mounted() {
      if (this.loggedIn) {
        this.$router.push("/profile");
      }
    },
    methods: {
      handleRegister(user) {
        this.message = "";
        this.successful = false;
        this.loading = true;
  
        this.$store.dispatch("auth/register", user).then(
          (data) => {
            this.message = data.message;
            this.successful = true;
            this.loading = false;
          },
          (error) => {
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            this.successful = false;
            this.loading = false;
          }
        );
      },
    },
  };
  </script>
  
  <style scoped>
  * {
    box-sizing: border-box;
  }
  
  label {
    padding: 12px 12px 12px 0;
    display: inline-block;
  }
  
  button[type="submit"] {
    background-color: #4caf50;
    color: white;
    padding: 12px 20px;
    cursor: pointer;
    border-radius: 30px;
  }
  
  button[type="submit"]:hover {
    background-color: #45a049;
  }
  
  input {
    margin: 5px;
    box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
    padding: 10px;
    border-radius: 30px;
  }
  #error {
    color: red;
  }
  </style>
<template>
  <div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
      <div class="content-wrapper d-flex align-items-center auth px-0">
        <div class="row w-100 mx-0">
          <div class="col-lg-4 mx-auto">
            <div class="auth-form-light text-left py-5 px-4 px-sm-5">
              <div class="brand-logo">
                <img src="images/logo.png" alt="logo">
              </div>
              <h4>Bem-vindo!</h4>
              <h6 class="font-weight-light">Faça login para continuar.</h6>
              <Form @submit="handleLogin" :validation-schema="schema" class="pt-3">
                <div class="form-group">
                  <Field name="email" type="email" class="form-control form-control-lg" placeholder="Email" />
                  <ErrorMessage name="email" class="error-feedback" />
                </div>
                <div class="form-group">
                  <Field name="password" type="password" class="form-control form-control-lg" placeholder="Password" />
                  <ErrorMessage name="password" class="error-feedback" />
                </div>
                <div class="form-group">
                  <div v-if="message" class="alert alert-danger" role="alert">
                  {{ message }}
                  </div>
                </div>
                <div class="form-group">
                  <button class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" name="login" :disabled="loading">
                    Log in
                  </button>
                </div>
                <div class="form-group">
                  <div class="text-center mt-4 font-weight-light">
                    Não tem conta? <a href="/register" class="text-primary">Criar agora</a>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <!-- content-wrapper ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
  </template>
  
  <script>
  import { Form, Field, ErrorMessage } from "vee-validate";
  import * as yup from "yup";
  
  export default {
    name: "Login-Page",
    components: {
      Form,
      Field,
      ErrorMessage,
    },
    data() {
      const schema = yup.object().shape({
        email: yup
          .string()
          .required("Email é obrigatório!")
          .email("Email é inválido!"),
        password: yup.string().required("Password é obrigatória!"),
      });
  
      return {
        loading: false,
        message: "",
        schema,
      };
    },
    computed: {
      loggedIn() {
        return this.$store.state.auth.status.loggedIn;
      },
    },
    created() {
      if (this.loggedIn) {
        this.$router.push("/");
      }
    },
    methods: {
      handleLogin(user) {
        this.loading = true;
  
        this.$store.dispatch("auth/login", user).then(
          () => {
            this.$router.push("/");
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      },
    },
  };
  </script>
  
  <style scoped>
  * {
    box-sizing: border-box;
  }
  
  label {
    padding: 12px 12px 12px 0;
    display: inline-block;
  }
  
  button[type="submit"] {
    background-color: #4caf50;
    color: white;
    padding: 12px 20px;
    cursor: pointer;
    border-radius: 30px;
  }
  
  button[type="submit"]:hover {
    background-color: #45a049;
  }
  
  input {
    margin: 5px;
    box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
    padding: 10px;
    border-radius: 30px;
  }
  #error {
    color: red;
  }
  </style>
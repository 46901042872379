import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://api.rodaprecos.pt/auth/';

class UserService {
  getUserBoard() {
    return axios.get(API_URL + 'home.php', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }

  getSearchResults({search, page, id}) {
    return axios.get(
      `https://api.rodaprecos.pt/search/get.php?search=${search}&environment=production&page=${page}&limit=20${id ? '&id='+id : ''}`
    , { headers: authHeader() });
  }
}

export default new UserService();
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { FontAwesomeIcon } from './plugins/font-awesome'
import VueAxios from "vue-axios";
import axios from "axios";
import CanvasJSChart from '@canvasjs/vue-charts';

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error.response.status === 401) {
    window.location.href = '/logout';
  }
  return Promise.reject(error);
});

createApp(App)
  .use(router)
  .use(store)
  .use(VueAxios, axios)
  .use(CanvasJSChart)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");